import { SiProgress } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { AiFillAppstore } from "react-icons/ai";
import { downloads } from "../assets/downloads/downloads";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <SiProgress />,
    title: "Frontend Development",
    des: "My projects stand out with a modern tech stack featuring React, JavaScript, Typescript, HTML5, CSS3, Styled-Components, and testing using Jest and React Testing Library. I'm passionate about crafting efficient and stylish web solutions.",
  },
  {
    id: 2,
    icon: <CgWebsite />,
    title: "Backend Development",
    des: "I'm building my expertise in backend application development, with a foundation in NodeJS, MongoDB, and initial experience in microservices architecture with Java and Springboot. Ready to grow and excel.",
  },
  {
    id: 3,
    icon: <AiFillAppstore />,
    title: "App Development",
    des: "Dedicated to mastering React Native, I'm deeply committed to honing my skills for crafting versatile cross-platform applications. With a relentless focus on mobile development, I prioritize continuous learning and advancement in the field.",
  },
];
export const techExperience = [
  {
    title: "Software Developer",
    subTitle: "Viewnext (IBM Spain), Spain. 02/2024 - Present",
    description: `Key contributor to the development of web components and applications, with notable involvement in the prestigious Caixabank project:
      - Spearheaded front-end development efforts within the Caixabank project, leveraging the internal framework OpenFront.
      - Proficiently implemented unit testing methodologies using Jest and React Testing Library, ensuring robustness and reliability.
      - Actively participated in a dynamic, Agile-driven environment as part of a multidisciplinary team, fostering collaboration and innovation.`,
    link: undefined,
  },
  {
    title: "Software Developer",
    subTitle: "Rodomnia GmbH, Germany. 01/2022 - 01/2024",
    description: `Played a pivotal role in developing diverse web applications catering to varied business needs, including:
      - Orchestrated the development of platforms facilitating the buying and selling of metals, optimizing transactional workflows.
      - Led the creation of a comprehensive application for employee scheduling and task management, enhancing operational efficiency.
      - Utilized React and TypeScript to architect scalable solutions while adhering to rigorous quality standards.
      - Designed intuitive user interfaces and responsive layouts using industry-standard CSS frameworks like SAAS.
      - Implemented thorough unit testing protocols with Jest and React Testing Library to uphold code integrity.
      - Collaborated seamlessly within a multidisciplinary Agile team, fostering a culture of continuous improvement and delivery.`,
    link: undefined,
  },
];
export const othersExperience = [
  {
    title: "Field service engineer.",
    subTitle:
      "Barberan S.A. Barcelona, Spain 03/2020 - 06/2022;SSI Schaefer S.L Barcelona, Spain 02/2019 - 03/2020",
    description: `As a Field Service Engineer from February 2019 to January 2022, I specialized in the commissioning
  and maintenance of industrial machinery and electrical installations. I also focused on
  troubleshooting electrical and electronic issues and performed assembly work for electrical
  components and panels. My expertise lies in comprehensive management of complex electrical
  systems.`,
    link: undefined,
  },
  {
    title: "Electrical engineer",
    subTitle:
      "Inselcom S. L. Madrid, Spain 04/2018 – 11/2018;ElektroScherer GmbH. Ulm, Germany 03/2014 – 03/2018",
    description: `As an Electrical Engineer, I possess extensive expertise in programming automatic systems using Simatic S7 and KNX/EIB software, 
    deploying Cisco industrial network systems, and conducting inspections for electrical installations. Throughout my tenure, 
    I've demonstrated proficiency in designing and implementing tailored solutions, ensuring seamless integration and optimal performance. 
    Additionally, I've played a pivotal role in establishing resilient communication infrastructures and verifying compliance with safety regulations, 
    contributing significantly to the efficiency and reliability of industrial operations`,
    link: undefined,
  },
];

export const jobExperience = [...techExperience, ...othersExperience];
// Education Data
export const educationDes = [
  {
    title: "Advanced expert in electrotechnical facilities. ISCED 5. 2000 h",
    school: "IES Alhamilla, Almería (2018 - 2010)",
    description: `As an advanced expert in electrotechnical facilities with ISCED level 5 accreditation and 2000 hours of training, I specialize in developing and supervising the implementation of electrical distribution systems across medium and low voltage networks, including transformation centers. My expertise extends to designing specific electrical and automation solutions for domestic, commercial, and industrial applications, ensuring alignment with client specifications and regulatory standards. I oversee the entire construction process, from commissioning to maintenance, optimizing resource allocation for quality outcomes. Operating under the supervision of qualified professionals like Architects or Engineers, I guarantee the delivery of safe, compliant, and high-quality electrotechnical installations.`,
    link: downloads.euresSuplement,
  },
  {
    title:
      "Master in Automatic Systems and Industrial Electronics Engineering. 600h",
    school: "INESEM Bussines School, Granada.(2017 - 2018)",
    description: `During my Masters program in Automatic Systems and Industrial Electronics Engineering at INESEM Business School in Granada from 2017 to 2018, I specialized in PLC software development under the standard IEC 61131-3, with a focus on utilizing Siemens TIA-Portal for developing automatic systems. My coursework involved comprehensive studies on system implementation and fieldbus analysis to determine the most suitable options for specific applications. Through hands-on projects and practical exercises, I gained proficiency in software development for PLCs using TIA Portal, enabling me to design and implement efficient and reliable automated systems tailored to industry requirements.`,
    link: downloads.inesemMaster,
  },
];

export const certificates = [
  {
    title: "Master in  Web Development. 1000h",
    school: "ISDI Coders, Barcelona.(2022)",
    description: `During my role, I spearheaded the development of web applications using React, with a focus on creating seamless user experiences. I utilized Redux for efficient state management on the frontend, ensuring robust and scalable applications.

    On the backend, I took charge of building RESTful APIs connected to MongoDB databases, enabling smooth data interactions and efficient data handling.
    
    I led the development process using React Framework, ensuring responsive design and achieving a commendable 100% testing code coverage. My proficiency extends to a wide range of JavaScript frameworks and web technologies, including React/Redux, CSS, SASS, and HTML.
    
    Moreover, I possess in-depth knowledge of database structures, including Supabase, and adhere to industry best practices such as SOLID principles. My expertise also extends to comprehensive testing methodologies, including unit tests using Jest and end-to-end tests with Cypress. Additionally, I am well-versed in version control using Git.
    
    My contributions in these areas have consistently resulted in the successful delivery of high-quality, scalable web solutions, meeting client expectations and driving project success.`,
    link: downloads.isdiCodersBootcamp,
  },
];

export const skills = {
  markupAndLanguages: ["html", "css", "javascript", "typescript", "java"],
  frontendTechnologies: [
    "react",
    "redux",
    "nextjs",
    //"styledcomponents",
    "sass",
    // "webpack",
    "vite",
    //"chackra-ui",
  ],
  backendAndDatabases: ["nodejs", "mongodb" /*"spring", "mysql"*/],
  toolsAndOther: ["git", "figma", "trello", "jenkins"],
};
